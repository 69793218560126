<template>
  <v-select
    v-model="algoritmo"
    name="algoritmo"
    :label="label"
    :items="algoritmos"
    item-value="id"
    item-text="nome"
    :outlined="type === 'outlined'"
    class="mb-0 pb-0"
    no-data-text="Nenhuma regra de setorização disponível"
    :loading="loading"
    @change="algoritmoSelecionado"
  />
</template>

<script>
import AlgoritmosSetorizacoesService from '@/services/AlgoritmosSetorizacoesService';

export default {
  name: 'SelectAlgoritmosSetorizacoes',
  props: {
    type: {
      type: String,
      default: 'solo'
    },
    label: {
      type: String,
      default: 'Regra de setorização'
    },
    value: {
      type: Number
    }
  },
  data() {
    return {
      algoritmo: '',
      algoritmos: [],
      loading: false
    };
  },
  mounted() {
    this.getAlgoritmos();
  },
  methods: {
    getAlgoritmos() {
      this.loading = true;
      AlgoritmosSetorizacoesService.getAlgoritmosAtivos()
        .then((response) => (this.algoritmos = response.data.data))
        .finally(() => {
          if (this.value) {
            this.algoritmo = this.value;
            this.algoritmoSelecionado(this.value);
          }
          this.loading = false;
        });
    },
    algoritmoSelecionado(event) {
      const algoritmoSelecionado = this.algoritmos.find(
        (algoritmo) => algoritmo.id === event
      );
      this.$emit('algoritmoSelecionado', algoritmoSelecionado);
    }
  }
};
</script>
